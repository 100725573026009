import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { firestore } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, Row, Col} from 'react-bootstrap';
import backgroundImage from './bg2.jpg';
import $ from 'jquery';


// firebase.initializeApp(config);
// var db = firebase.firestore();

const App = () => {
  const [personName, setPersonName] = useState('');
  const [reaction, setReaction] = useState('');
  const [page, setPage] = useState(1);
  const [gifs, setGifs] = useState([]);
  const [selectedGif, setSelectedGif] = useState(null);
  const [numImagesPerRow, setNumImagesPerRow] = useState(5); // Default number of images per row



  const handleGifSelection = (gif) => {
    // Do something with the selected gif, e.g., store it in state
    setSelectedGif(gif);

    // You can add any other logic you need based on the selected gif
    console.log('Selected GIF:', gif);
  };

  useEffect(() => {
    // Adjust the number of images per row based on screen size
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1200) {
        setNumImagesPerRow(5);
      } else if (screenWidth >= 992) {
        setNumImagesPerRow(4);
      } else {
        setNumImagesPerRow(3);
      }
    };

    // Initial adjustment on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContinue = () => {
    setPage(2);
  };

  const handleNext1 = async () => {
    fetchGifs(reaction);
    setPage(3);
  };

   // Function to fetch gifs from Giphy API based on user input
   const fetchGifs = async (query) => {
    try {
      const apiKey = 'mo3zGvrfBMac1AaWF9Z4YQKH8NKMSLvS'; // Replace with your Giphy API key
      const url = `https://api.giphy.com/v1/gifs/search?q=${'happy'}&api_key=${apiKey}&limit=5`;
      const response = await fetch(url);
      const data = await response.json();
      console.log('gifs: ', data)
      console.log('images: ',data)
      setGifs(data.data);
    } catch (error) {
      console.error('Error fetching gifs:', error);
    }
  };

  const handleNext2 = async () => {
    const docRef = await addDoc(collection(firestore, 'reactions'), {
      name: personName,
      reaction: selectedGif.images.original.url,
    });
    // Fetch gifs based on the user's reaction input
    // await fetchGifs(reaction);
    setPage(4);
  };

  const handleUploadContact = async () => {
    try {
      if (navigator.contacts) {
        const { status } = await navigator.contacts.requestPermissions(); // Request contacts permission
  
        if (status === 'granted') {
          const contacts = await navigator.contacts.pickContact(); // Pick a contact
  
          if (contacts) {
            const contactName = contacts.displayName || contacts.name?.formatted || 'Unknown';
            setPersonName(contactName);
            console.log(`Contact uploaded: ${contactName}`);
          }
        } else {
          console.log('Contacts permission denied');
        }
      } else {
        console.log('navigator.contacts is not supported in this environment');
      }
    } catch (error) {
      console.error('Error fetching or uploading contacts:', error);
    }
  };

  const handleRestart = () => {
    setPersonName('');
    setReaction('');
    setPage(1);
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {page === 1 && (
                  <>
                  <div>
                    <h3 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', marginTop: 30, width: '80%' }}>Relax, It's just a game! </h3>
                  </div>
                    {/* <p>Relax, It's just a game</p> */}
                    <Button style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, marginTop: 'auto', borderRadius: 30, height: 60, width: '90%', margin: 30, backgroundColor:'#E4AD00', color:'#FFFFFF' }}
      variant="light" onClick={handleContinue}>P l a y</Button>
                  </>
                )}
                {page === 2 && (
                  <>
                  <div>
                    <h3 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', marginTop: 30, width: '80%' }}>Write the name of a friend</h3>
                  </div>
                    {/* <p>Write the name of a friend</p> */}
                    <Form style={{fontFamily: 'Lexend Zetta', height: 45, margin: 5, marginTop: 0, borderRadius:30, width: '80%'}} variant="outline-dark">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        {/* <Form.Label>Who's pressing your buttons?</Form.Label> */}
                        <Form.Control type="text" value={personName}
                      onChange={(e) => setPersonName(e.target.value)} style={{height: 60, textTransform: "capitalize"}} placeholder="example: Andrew Tate" />
                      </Form.Group>
                    </Form>
                    <Button style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, marginTop: 'auto', borderRadius: 30, height: 60, width: '90%', margin: 30, backgroundColor:'#E4AD00', color:'#FFFFFF' }}
                  variant="light" 
                  onClick={handleNext1}>N e x t</Button>
                  </>
                )}
                {page === 3 && (
                  <>
                    <div>
                    <h3 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', marginTop: 30, width: '80%' }}>Imagine {personName} has been God this whole time, pretending to be human and taking notes.</h3>
                  </div>
                  {/* <p>Submit your reaction using only emoji!</p> */}

                  {/* <Form style={{fontFamily: 'Lexend Zetta', height: 45, margin: 5, marginTop: 0, borderRadius:30, width: '80%'}} variant="outline-dark"> */}
                      {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1"> */}
                        {/* <Form.Label>Who's pressing your buttons?</Form.Label> */}
                        {/* <Form.Control type="text" value={reaction}
                      // onChange={(e) => setReaction(e.target.value)} style={{height: 60, textTransform: "capitalize"}} placeholder="Submit your reaction using only emoji!" 
                      onChange={(e) => setReaction(e.target.value)}
                      onBlur={() => fetchGifs(reaction)} // Fetch gifs on blur (when the user finishes typing)
                      style={{ height: 60, textTransform: 'capitalize' }}
                      placeholder="Submit your reaction using only emoji!"

                      /> */}
                     
                      {/* </Form.Group> */}
                    {/* </Form> */}
                    <div class="row">
                    <div class="col text-center">
                   {/* Display the fetched gifs */}
                    {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, flexWrap: 'wrap' }}> */}
                    <Row className="g-2" style={{ margin: '5px -2.5px' }}>
                    <Col xs={12} sm={12} md={5} lg={12 / numImagesPerRow} style={{ marginBottom: 10 }}>
                      {gifs.map((gif, id) => (
                        <img
                          key={id}
                          src={gif.images.original.url}
                          alt={gif.title}
                          style={{
                          width: 100,
                          height: 100,
                          margin: '10px 0',
                          cursor: 'pointer',
                        }}  onClick={() => handleGifSelection(gif)}
                        />
                      )
                      )}
                      </Col>
                      </Row>
                    </div>
                    </div>
                    
                    <Button style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, marginTop: 'auto', borderRadius: 30, height: 60, width: '90%', margin: 30, backgroundColor:'#E4AD00', color:'#FFFFFF' }}
      variant="light" onClick={handleNext2}>N e x t</Button>
                  </>
                )}
                {page === 4 && (
                  <>
                  <div>
                    <h3 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', marginTop: 30, width: '80%' }}>Share reaction with {personName} ?</h3>
                  </div>
                    {/* <p>Reaction submitted successfully!</p> */}
                    <Button style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, marginTop: 'auto', borderRadius: 30, height: 60, width: '90%', margin: 30, backgroundColor:'#E4AD00', color:'#FFFFFF' }}
      variant="light" onClick={handleRestart}>W h a t s a p p</Button>
                    {/* <Button style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, marginTop: 'auto', borderRadius: 30, height: 60, width: '90%', margin: 30, marginTop: 0, backgroundColor:'#E4AD00', color:'#FFFFFF' }}
      variant="light" onClick={handleUploadContact}>C o n t a c t</Button> */}
                  </>
                )}
              </>
            }
          />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
